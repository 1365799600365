// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ahjud-jsx": () => import("./../../../src/pages/ahjud.jsx" /* webpackChunkName: "component---src-pages-ahjud-jsx" */),
  "component---src-pages-ettevottest-jsx": () => import("./../../../src/pages/ettevottest.jsx" /* webpackChunkName: "component---src-pages-ettevottest-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kaminad-jsx": () => import("./../../../src/pages/kaminad.jsx" /* webpackChunkName: "component---src-pages-kaminad-jsx" */),
  "component---src-pages-kkk-jsx": () => import("./../../../src/pages/kkk.jsx" /* webpackChunkName: "component---src-pages-kkk-jsx" */),
  "component---src-pages-pliidid-jsx": () => import("./../../../src/pages/pliidid.jsx" /* webpackChunkName: "component---src-pages-pliidid-jsx" */),
  "component---src-pages-saadetud-js": () => import("./../../../src/pages/saadetud.js" /* webpackChunkName: "component---src-pages-saadetud-js" */),
  "component---src-pages-vota-yhendust-jsx": () => import("./../../../src/pages/vota-yhendust.jsx" /* webpackChunkName: "component---src-pages-vota-yhendust-jsx" */)
}

